/** =============================================================================
OWL SLIDER
============================================================================ */

$(document).ready(function() {
  // Init Sliders
  mSlider01();
  mSlider02();
});

/**
 * Owl Slider 01
 * -------------------------------------------------- */

// Pad a number with leading zeroes
function pad(n, len) {
  s = n.toString();
  if (s.length < len) {
    s = ('0000000000' + s).slice(-len);
  }

  return s;
}

function mSlider01() {
  var mSlider01 = $('.js-m-slider-01');
  var msPagination01 = mSlider01
    .closest('.m-slider-01')
    .find('.m-slider-01__counter');
  var msPageCurrent01 = msPagination01.find('.m-slider-01__current');
  var msPageTotal01 = msPagination01.find('.m-slider-01__total');

  mSlider01.on('initialized.owl.carousel changed.owl.carousel', function(e) {
    if (!e.namespace) {
      return;
    }
    var carousel = e.relatedTarget;
    var current = carousel.relative(carousel.current()) + 1;
    var total = carousel.items().length;

    msPageCurrent01.text(pad(current, 2));
    msPageTotal01.text(pad(total, 2));
  });

  mSlider01.owlCarousel({
    items: 1,
    loop: true,
    rewind: false,
    margin: 0,
    nav: true,
    dots: false,
    smartSpeed: 440,
    lazyLoad: false,
    autoplay: mSlider01.data('autoplay'),
    autoplayTimeout: 12000,
    autoplayHoverPause: true,
    navContainerClass: 'm-slider__nav m-slider-01__nav',
    navClass: ['m-slider__nav-prev', 'm-slider__nav-next'],
    navText: [
      '<svg class="m-slider-0__nav-icon" width="25" height="45"><use xlink:href="#symbol-arrow-left"></use></svg>',
      '<svg class="m-slider-0__nav-icon" width="25" height="45"><use xlink:href="#symbol-arrow-right"></use></svg>'
    ]
  });
}

/**
 * Owl Slider 02
 * -------------------------------------------------- */

function mSlider02() {
  var mSlider02 = $('.js-m-slider-02');
  var mSlider02slide = $('.m-slider-02__slide');

  mSlider02.children().each(function(index) {
    $(this).attr('data-position', index);
  });

  mSlider02.owlCarousel({
    items: 1,
    loop: true,
    rewind: false,
    margin: 0,
    nav: true,
    dots: true,
    smartSpeed: 440,
    lazyLoad: false,
    autoplay: mSlider02.data('autoplay'),
    autoplayTimeout: 15000,
    autoplayHoverPause: true,
    dotsClass: 'm-slider-02__pages',
    dotClass: 'm-slider-02__page',
    navContainerClass: 'm-slider__nav m-slider-02__nav',
    navClass: ['m-slider__nav-prev', 'm-slider__nav-next'],
    navText: [
      '<svg class="m-slider__nav-icon" width="25" height="45"><use xlink:href="#symbol-arrow-left"></use></svg>',
      '<svg class="m-slider__nav-icon" width="25" height="45"><use xlink:href="#symbol-arrow-right"></use></svg>'
    ]
  });

  mSlider02slide.click(function() {
    mSlider02.trigger('to.owl.carousel', $(this).data('position'));
  });

  $('.owl-item, .owl-prev, .owl-next, .owl-dot span').on('click',function(){
    $('.owl-carousel').trigger('autoplay.stop.owl')
  })
}
