/**
 * A11Y Dropdown
 * based on: https://codepen.io/viktorkovalenko/pen/MrRdQK
 * -------------------------------------------------- */

var $dropdownToggle = $('.js-dropdown-toggle');
var $dropdownItems = $('.js-dropdown-items');
var $dropdownItem = $('.js-dropdown-items li');

function closeAllLists() {
  // $(dropdownItems).attr('hidden', 'hidden')
  $dropdownItems.removeClass('is-open'); // .slideUp(200)
  $dropdownToggle.removeClass('is-open');
  $dropdownToggle.attr('aria-expanded', false);
}

// Initially add the hidden attribute
// $(dropdownItems).attr('hidden', 'hidden')

// On item click
$dropdownToggle.on('click', function(event) {
  var $clickedElement = $(this);
  var $nextItems = $clickedElement.next($dropdownItems);

  $clickedElement
    .toggleClass('is-open')
    .attr('aria-expanded', function(i, attr) {
      return attr == 'true' ? 'false' : 'true';
    });

  $nextItems.toggleClass('is-open');

  // if ($nextItems.hasClass('is-open')) {
  //   $nextItems.removeAttr('hidden')
  // } else {
  //   $nextItems.attr('hidden', 'hidden')
  // }
});

// Close Dropdown when clicked outside
$(document).on('click', function(event) {
  if ($dropdownItems.hasClass('is-open')) {
    if (
      !$dropdownToggle.is(event.target) &&
      $dropdownToggle.has(event.target).length === 0 &&
      !$dropdownItems.is(event.target) &&
      $dropdownItems.has(event.target).length === 0
    ) {
      closeAllLists();
    }
  }
});

// Close dropdown on ESC
$(document).keydown(function(event) {
  if (event.keyCode === 27) {
    closeAllLists();
  }
});

//
// var fieldWrapper = $('.js-dropdown-toggle'),
//   list = fieldWrapper.find('.js-dropdown-list'),
//   listElement = list.find('.js-dropdown-list-item"]')

// function closeAllLists () {
//   fieldWrapper.attr('aria-expanded', false)
//   list.removeClass('active')
// }

// function closeDropdown (el) {
//   el.closest(fieldWrapper).attr('aria-expanded', false)
//   el.closest(list).removeClass('active')
// }

// function openDropdown (el) {
//   closeAllLists()
//   el.closest(fieldWrapper).attr('aria-expanded', true)
//   el.closest(list).addClass('active')
// }

// function toggleDropdown (el) {
//   if (el.closest(list).hasClass('active')) {
//     closeDropdown(el)
//   } else {
//     openDropdown(el)
//   }
// }

// function setValue (el) {
//   listElement.removeClass('current')
//   el.addClass('current')

//   el.closest(list).find('li.initial')
//     .attr('data-value', el.attr('data-value'))
//     .html(el.html())

//   el.closest(fieldWrapper).find(input).val(el.attr('data-value'))
// }

// // Set value on click on option
// listElement.on('click', function () {
//   if (!$(this).is('.initial') && $(this).closest(list).hasClass('active')) {
//     setValue($(this))
//   }
//   toggleDropdown($(this))
// })

// // Close dropdown if clicked outside
// $(document).on('click', function (e) {
//   if (list.hasClass('active')) {
//     if (!list.is(e.target) && !listElement.is(e.target)) {
//       closeAllLists()
//     }
//   }
// })

//$(document).keydown(function (e) {
//   var t = e.target,
//     fEl = $(document.activeElement)

//   // Close dropdown on ESC
//   if (e.keyCode === 27) {
//     closeAllLists()
//   }

//   // Toggle dropdown open on :focus with enter
//   if (e.keyCode === 13 && $(t).hasClass('initial')) {
//     toggleDropdown(fEl)
//   }

//   // Set value if :focus is on actual option
//   if (e.keyCode === 13 && $(t).is(listElement) && !$(t).hasClass('initial')) {
//     setValue(fEl)
//     closeDropdown(fEl)
//   }
// })