/**
 * Read more / toggle links
 * -------------------------------------------------- */

$('.js-expand-text-full')
  .hide()
  .each(function() {
    var open_text = $(this).data('open-text');
    open_text = typeof open_text !== 'undefined' ? open_text : 'mehr';
    $(this).after(
      '<button class="m-expand-text__toggle js-expand-text-toggle">' +
        open_text +
        '</button>'
    );
  });

$('.js-expand-text-toggle').click(function(e) {
  e.preventDefault();

  var open_text = $(this)
    .prev('.js-expand-text-full')
    .data('open-text');
  var close_text = $(this)
    .prev('.js-expand-text-full')
    .data('close-text');

  if (typeof open_text == 'undefined') {
    open_text = 'mehr';
  }
  if (typeof close_text == 'undefined') {
    close_text = 'weniger';
  }

  if ($(this).text() == open_text) {
    $(this)
      .html(close_text)
      .prev('.js-expand-text-full')
      .slideToggle()
      .after(this);
    // $(".js-expand-text-preview").addClass("is-open")
    $(this)
      .closest('.m-expand-text')
      .find('.js-expand-text-preview')
      .addClass('is-open');
  } else {
    $(this)
      .html(open_text)
      .prev('.js-expand-text-full')
      .slideToggle()
      .after(this);
    //  $(".js-expand-text-preview").removeClass("is-open")
    $(this)
      .closest('.m-expand-text')
      .find('.js-expand-text-preview')
      .removeClass('is-open');
  }
});
