/**
 * Stop Loading Links - In case you want to trigger a script
 * -------------------------------------------------- */

$('a.js-no-link').click(function(e) {
  e.preventDefault();
  e.stopPropagation();
});

/**
 * Simple touch device detect
 * -------------------------------------------------- */
// function is_touch_device() {
//   return !!("ontouchstart" in window)
// }
//
// if (is_touch_device()) {
//   $("html").addClass("touch")
// } else {
//   $("html").addClass("no-touch")
// }

/**
 * Disable pointer-events while scrolling
 * src: https://www.thecssninja.com/javascript/pointer-events-60fps
 * -------------------------------------------------- */
// var body = document.body,
//   timer
//
// window.addEventListener(
//   "scroll",
//   function() {
//     clearTimeout(timer)
//     if (!body.classList.contains("u-disabled--pointer-events")) {
//       body.classList.add("u-disabled--pointer-events")
//     }
//
//     timer = setTimeout(function() {
//       body.classList.remove("u-disabled--pointer-events")
//     }, 500)
//   },
//   false
// )
