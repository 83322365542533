/**
 * m-partner
 * -------------------------------------------------- */

var $navItem = $('.js-partner-nav-item');

function findMatch(clickedItem) {
  var attr = clickedItem.data('pin');
  var matchingItem;

  if (clickedItem.hasClass('m-partner__nav-item-btn')) {
    reset();
    // find matching mappin and reassign matchingItem variable
    matchingItem = $(
      '.js-partner-map .m-partner__map-pin[data-pin="' + attr + '"]'
    );
  }

  if (clickedItem.hasClass('m-partner__map-pin')) {
    reset();
    // find matching navitem and reassign matchingItem variable
    matchingItem = $(
      '.js-partner-nav .m-partner__nav-item-btn[data-pin="' + attr + '"]'
    );
  }

  // add class to matching pin or button
  matchingItem.addClass('is-active');

  // activate clicked button/pin
  clickedItem.addClass('is-active');

  // open desc
  $('.m-partner__nav-item-btn.is-active')
    .next('.m-partner__nav-item-desc')
    .slideDown(250);
}

function reset() {
  // close all desc-container
  $('.m-partner__nav-item-btn')
    .next('.m-partner__nav-item-desc')
    .slideUp(250);
  // reset all other buttons/pins
  $navItem.removeClass('is-active');
}

$navItem.click(function(event) {
  if ($(this).hasClass('is-active')) {
    reset();
  } else {
    findMatch($(this));
  }
});
