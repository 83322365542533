/**
 * Burger Navigation
 * -------------------------------------------------- */

// var currentToggleText = $('#js-menu-toggle .c-burger__label').text();
var $menuToggle = $('#js-menu-toggle');

$menuToggle.on('click', function(e) {
  var $trigger = $(this);
  var $toggleText = $menuToggle.find('.c-burger__label');

  $('#js-menu').toggleClass('is-visible');
  $('body').toggleClass('menu-is-visible');

  $trigger.toggleClass('is-open');

  // if ($trigger.hasClass('is-open')) {
  //   $toggleText.text('schließen');
  // } else {
  //   $toggleText.text(currentToggleText);
  // }

  if (!$trigger.hasClass('is-open')) {
    $trigger.addClass('is-closing');
    setTimeout(function() {
      $trigger.removeClass('is-closing');
    }, 350);
  }

  // close overlay wih esc-key
  $(document).keydown(function(es) {
    if ($menuToggle.hasClass('is-open') && es.which == 27) {
      $menuToggle.trigger('click');
    }
  });
});
