/**
 * A11Y Accordion - REFACTORED jQuery version
 * based on https://codepen.io/heydon/pen/gGNaoM
 * https://inclusive-components.design/collapsible-sections/
 * -------------------------------------------------- */

var accordionContent = '.js-accordion-content';
var accordionButton = '.js-accordion-btn';

// Initially add the hidden attribute
$(accordionContent).attr('hidden', 'hidden');

// On item click
$('.js-accordion-item').click(function(e) {
  $(this).toggleClass('is-open');
  $(this)
    .children(accordionButton)
    .attr('aria-expanded', function(i, attr) {
      return attr == 'true' ? 'false' : 'true';
    });

  $(this)
    .children(accordionContent)
    .slideToggle(200, function() {
      if ($(this).attr('hidden')) {
        $(this).removeAttr('hidden');
      } else {
        $(this).attr('hidden', 'hidden');
      }
    });
});
